/* Sidebar.css */

.sidebar {
  width: 250px; /* Adjust width as needed */
  background: #000; /* Dark background for the sidebar */
  color: #ffffff;
  height: 100vh;
  overflow-y: auto; /* Allow scrolling for overflow content */
  z-index: 1000;
}

.sidebar-header {
  padding: 20px;
  background: #23272a; /* Slightly darker background for the header */
}

.sidebar-header button {
  background-color: #35c58c;
  color: white;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.sidebar-header button:hover {
  background-color: #4b58d6; /* Slightly darker blue on hover */
}

.thread-list {
  padding: 20px;
}

.thread-item {
  padding: 10px;
  margin-bottom: 10px;
  background: #2c2f33; /* Same as sidebar background */
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
}

.thread-item:hover, .thread-item.selected:hover {
  background: #3a3f44; /* Slightly lighter on hover */
  color: #fff;
}

.thread-item.selected {
  background-color: #fff; /* A lighter color for selected items */
  color: #000; /* White text color for better visibility */
}

.close-sidebar {
  display: none; /* Hidden by default */
  background-color: transparent;
  border: none;
  color: white;
  font-size: 24px;
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
}

.menu-button {
  background: none;
  border: none;
  color: inherit; /* Inherit text color from the parent for better visibility */
  cursor: pointer;
  padding: 0 12px; /* Add some padding for clickable area */
}

.thread-menu {
  background: #23272a;
  padding: 10px;
  margin-top: 5px;
}

.delete-thread {
  background-color: #ff4d4d; /* Red color for delete button */
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.update-thread {
  background-color: #637df4;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

/* Mobile compatibility */
@media screen and (max-width: 768px) {
  .sidebar {
    width: 100%;
    position: fixed;
    top: 0;
    left: -100%; /* Initially hidden */
    height: 100vh; /* Full viewport height */
    transition: left 0.3s;
  }

  .hide-sidebar {
    left: -100%;
  }

  .show-sidebar {
    left: 0;
  }

  .close-sidebar {
    display: block; /* Make the close button visible on mobile */
  }

  .hamburger-menu {
    display: block;
    position: fixed;
    top: 12px;
    left: 20px;
    background-color: #35c58c;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
  }
}

.edit-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(67, 65, 65);
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  z-index: 100;
}
.edit-popup input {
  margin-bottom: 10px;

}

.edit-popup button {
  background-color: #637df4;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 5px;
}

.delete-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(67, 65, 65);;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  z-index: 100;
}

.delete-popup button {
  margin: 10px;
  padding: 5px 10px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  cursor: pointer;
}

/* Style for larger screens */
@media screen and (min-width: 769px) {
  .hamburger-menu {
    display: none;
  }
}