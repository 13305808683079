/* Chat.css */

/* Reset margins and padding */
* {
  margin: 0;
  padding: 0;
}

body, html {
  max-height: 100%;
  font-family: 'Roboto', sans-serif;
  color: #fff;
  box-sizing: border-box;
}

.app-container {
  display: flex;
  height: 100vh;
}

/* Main chat container styling */
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #ffffff;
  flex-grow: 1;
}

/* Header styling */
.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-left: 60px; /* Increase left padding to prevent overlap with hamburger menu */
  background-color: #ffffff; /* Dark header background */
  color: black;
}

/* Logout button styling */
.logout-button {
  padding: 8px 15px;
  background-color: #dc3545; /* Red color for logout button */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #bd2130; /* Darker red on hover */
}

/* Chat message area styling */
.chat-messages {
  flex-grow: 1;
  overflow-y: scroll; /* Ensures scrollbar is always present */
  padding: 0;
  background: #ffffff;
  width: calc(100vw - 250px);
}

/* Hide the scrollbar if necessary */
.chat-messages::-webkit-scrollbar {
  width: 0;
}

.chat-messages {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  max-height: 90vh;
  position: relative;
}

/* Ensure paragraphs and other block-level elements stack vertically */
.message p,
.message ul,
.message ol,
.message blockquote {
  margin: 0; /* Reset default margins */
  padding: 0.5rem 1rem; /* Add consistent padding for all block-level elements */
  line-height: 1.5; /* Improve readability */
  display: block; /* Ensure block display */
}

/* Adjust markdown list styles */
.message ul,
.message ol {
  padding-left: 1.5rem; /* Indent lists */
}

/* Ensure that code blocks are displayed correctly */
.message code {
  display: inline-block; /* Ensure code blocks do not affect layout */
  padding: 0.2rem 0.4rem;
  background-color: #f1f1f1;
  border-radius: 4px;
  font-family: monospace; /* Use monospace font for code */
}

/* Maintain the original styling for user and system messages */
.message.user,
.message.system {
  background-color: #e9ecef; /* Default background */
  color: #212529; /* Text color */
  text-align: left;
  padding: 10px;
  border-top: 0.5px solid #c8c8c8;
}

/* Specific background colors for user and system messages */
.message.user {
  background-color: #e9ecef; /* Light grey for user messages */
}

.message.system {
  background-color: #f8f9fa; /* Slightly lighter grey for system messages */
}

/* Padding adjustments for paragraphs inside messages */
.message.user p,
.message.system p {
  padding-left: 2rem; /* Consistent left padding */
  padding-right: 2rem; /* Consistent right padding */
  padding-top: 5px; /* Consistent top padding */
  padding-bottom: 5px; /* Consistent bottom padding */
}

/* Blockquote styling for messages */
.message blockquote {
  border-left: 4px solid #ddd; /* Style for blockquote */
  padding-left: 1rem; /* Indent for blockquote */
  margin-left: 1rem; /* Additional margin to create a blockquote effect */
}

/* Handle loading dots */
.loading-dots div {
  background-color: #000;
}

.chat-input-textarea {
  width: 100%; /* Take the full width of the container */
  padding: 15px; /* Same as your input padding */
  margin-right: 10px; /* Same as your input margin */
  background: #666; /* Lighter dark background for input */
  color: white;
  border: none;
  border-radius: 20px;
  resize: none; /* Disable manual resizing */
  line-height: 1.5; /* Adjust to your design */
  max-height: 120px; /* Adjust this value based on your line height to fit 4 lines */
  transition: none;
  min-height: 20px; /* Minimum height to avoid too small appearance */
  height: auto; /* Start with auto height */
  overflow-y: auto; /* Hide scrollbar to avoid flickering */
  font-family: Helvetica;
}

/* Input area styling */
.chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #333; /* Dark background for input area */
  width: calc(100vw - 250px); /* Width = viewport width - 250px */
  bottom: 0; /* Stick to the bottom */
  right: 0; /* Align to the left */
}

.chat-input input {
  flex-grow: 1;
  padding: 15px;
  margin-right: 10px;
  background: #666; /* Lighter dark background for input */
  color: white;
  border: none;
  border-radius: 20px;
}

/* Send button styling */
.chat-input button {
  padding: 15px 20px;
  background-color: #35c58c; /* Green background for send button */
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #218838; /* Darker green on hover */
}

/* Loading animation styling */
@keyframes dotFlashing {
  0% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0;
  }
}

.loading-dots {
  display: inline-block;
  margin-left: 15px;
  mask-image: none !important;
  -webkit-mask-image: none !important; /* For Webkit-based browsers */
}

.loading-dots div {
  background-color: #000;
  width: 6px;
  height: 6px;
  margin: 0 2px;
  border-radius: 50%;
  display: inline-block;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.2s;
}

.loading-dots div:nth-child(2) {
  animation-delay: 0.4s;
}

.loading-dots div:nth-child(3) {
  animation-delay: 0.6s;
}

/* Free Messages */
.free-messages-info {
  margin-right: 20px; /* Right margin for spacing */
  color: #adb5bd; /* Light grey text color */
  font-weight: bold; /* Make the font bold */
  display: flex;
  align-items: center; /* Vertically center the text */
  justify-content: flex-end; /* Align text to the right */
  flex-grow: 1; /* Allow it to take up remaining space */
}

/* Account dropdown menu styles */
.account-menu {
  position: relative;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  background-color: #333;
  border: 1px solid #666;
  border-radius: 5px;
  padding: 10px;
  z-index: 10;
}

.dropdown-menu div {
  padding: 5px 10px;
  color: white;
  cursor: pointer;
}

.dropdown-menu div:hover {
  background-color: #555;
}

.alert-message {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  color: black;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 999; /* Below the alert but above everything else */
}


.alert-message p {
  margin-bottom: 10px;
}

.alert-message button {
  padding: 10px 20px;
  background-color: #35c58c; /* Or any color that fits your design */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* API response styling */
.message p h1, .message p h2, .message p h3, .message p h4, .message p h5, .message p h6 {
  margin: 0.5em 0; /* Spacing for headings */
}

.message p strong {
  font-weight: bold; /* Bold text */
}

.message p em {
  font-style: italic; /* Italic text */
}

.message p br {
  display: block; /* Ensure line breaks are displayed */
  margin: 0; /* No extra space for line breaks */
}

.message ul {
  list-style-type: circle;
  padding-left: 20px;
}

.message ol {
  list-style-type: decimal;
  padding-left: 20px;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .chat-messages, .chat-input {
    width: 100%; /* Full width on smaller screens */
    max-width: none;
  }

  .message {
    max-width: none;
  }

  .message.user,
  .message.system {
    max-width: 100%;
  }

  .message.user p,
  .message.system p {
    margin-left: 0;
    margin-right: 0;
  }

}

/*@media (max-width: 600px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: fixed;
    left: -100%;=
    top: 0;
    transition: left 0.3s;
  }

  .sidebar.open {
    left: 0;
  }
}*/

@media (max-width: 768px) {
  .chat-header {
    padding-left: 70px; /* Allocate space for the hamburger menu */
  }
  
  /* Adjust chat messages to not overlap with hamburger menu */
  .chat-messages {
    padding-top: 10px;
    width: 100%;
    margin-bottom: 75px;
  }
  
  /* Adjust chat input if necessary */
  .chat-input {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .chat-input {
    position: fixed; /* Use fixed positioning for better control */
    bottom: 0; /* Start at the bottom */
    left: 0; /* Align to the left */
    right: 0; /* Align to the right */
    width: 100%; /* Full width */
    /* Other styles as necessary */
  }
}

.katex-container {
  overflow-x: auto; /* Prevent content overflow */
  white-space: normal; /* Fix potential layout breaking */
  display: inline-block; /* Ensure LaTeX spans only take necessary space */
  max-height: 100%;
}

.markdown {
  max-width: 100%; /* Prevent stretching */
  word-break: break-word; /* Handle long formulas */
  max-height: 100%;
}

.katex, .katex-display {
  max-width: 100%; /* Prevent horizontal overflow */
  overflow-x: auto; /* Allow scrolling for wide formulas */
  overflow-y: visible; /* Ensure multi-line formulas are fully visible */
  margin: 0 auto; /* Center align block formulas */
  padding: 0; /* Remove unintended padding */
  display: block; /* Ensure consistent block behavior */
}

.markdown .katex-display {
  margin-top: 1rem; /* Optional: Adjust spacing */
  margin-bottom: 1rem;
}

.katex {
  font-size: 1rem; /* Consistent font size */
  line-height: 1.5; /* Match app's line-height */
  overflow-y: hidden;
  overflow: scroll hidden;
}

.katex-display {
  text-align: left; /* Align block formulas properly */
  font-size: 1.2rem; /* Adjust font size for block equations */
}